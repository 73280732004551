// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_FVivr{width:100%;max-width:600px;padding-top:10px}.title_5IhBg{color:#fff;font-size:32px;line-height:120%;font-weight:700;margin-bottom:32px}.subtitle_iqtmt{margin:24px 0;color:#fff;font-size:20px;font-weight:700;line-height:24px}.label_9yg4-{margin:24px 0 8px;color:#90a4ae;font-size:12px;font-weight:500;line-height:12px;text-transform:uppercase}.selector_rbwo8{display:grid;grid-template-columns:1fr 1fr;grid-gap:16px}.selector__variant_\\+Hp-a{display:flex;align-items:center;justify-content:flex-start;background:#2b3848;border:2px solid #2b3848;border-radius:12px;padding:16px;color:#fff;font-size:14px;font-weight:500;line-height:18px}.selector__variantActive_C9R6e{border:2px solid #1e88e5}.selector__icon_76rhR{width:24px;height:24px;padding:2px;margin-right:8px}.selector__iconRound_\\+ivYq{display:flex;align-items:center;justify-content:center;width:100%;height:100%;border-radius:50%;border:2px solid #556877}.selector__iconRoundActive_lQ67y{border:2px solid #1e88e5}.selector__iconDot_RZqQP{width:12px;height:12px;border-radius:50%;background:#1e88e5}.twoColumn_MYW7o{display:grid;grid-template-columns:1fr 1fr;grid-gap:16px;align-items:center}.fullWidth_W0GHO{width:100%}.details_a5kiq{display:grid;padding:24px 0;grid-gap:12px;grid-template-columns:1fr;border-top:1px solid #334153;border-bottom:1px solid #334153}.detail_-5-x\\+{display:flex;align-items:center;justify-content:space-between}.detail__key_Gc8rH{color:#90a4ae;font-size:16px;font-weight:500;line-height:20px}.detail__value_B29wX{color:#fff;font-size:16px;font-weight:500;line-height:20px}.save_wfVIg{width:100% !important;margin-top:40px}.save_wfVIg:disabled{opacity:.3}.fieldBlocked_W0lnv{display:flex;align-items:center;justify-content:flex-start;height:48px;width:100%;padding:0 16px;border-radius:8px;background:#1c2735;color:#90a4ae}.tabs_7iLhZ{margin:20px 0 0;height:60px;background:#232f3f;padding:12px 24px;border-radius:8px;width:max-content}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_FVivr",
	"title": "title_5IhBg",
	"subtitle": "subtitle_iqtmt",
	"label": "label_9yg4-",
	"selector": "selector_rbwo8",
	"selector__variant": "selector__variant_+Hp-a",
	"selector__variantActive": "selector__variantActive_C9R6e",
	"selector__icon": "selector__icon_76rhR",
	"selector__iconRound": "selector__iconRound_+ivYq",
	"selector__iconRoundActive": "selector__iconRoundActive_lQ67y",
	"selector__iconDot": "selector__iconDot_RZqQP",
	"twoColumn": "twoColumn_MYW7o",
	"fullWidth": "fullWidth_W0GHO",
	"details": "details_a5kiq",
	"detail": "detail_-5-x+",
	"detail__key": "detail__key_Gc8rH",
	"detail__value": "detail__value_B29wX",
	"save": "save_wfVIg",
	"fieldBlocked": "fieldBlocked_W0lnv",
	"tabs": "tabs_7iLhZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
