import { render, staticRenderFns } from "./BottomModal.vue?vue&type=template&id=c9bf51ac&scoped=true&lang=pug"
import script from "./BottomModal.vue?vue&type=script&lang=js"
export * from "./BottomModal.vue?vue&type=script&lang=js"
import style0 from "./BottomModal.vue?vue&type=style&index=0&id=c9bf51ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9bf51ac",
  null
  
)

export default component.exports