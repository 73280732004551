<script>
import CopyInput from '@/components/CopyInput/CopyInput'
import UsersService from '@/services/UsersService'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'LinkTelegram',

  components: {
    CopyInput
  },

  data () {
    return {
      tgData: undefined
    }
  },

  computed: {
    ...mapState('user', ['user']),

    isTelegramLinked () {
      return this.user.social_network?.some(({ provider }) => provider === 'telegram')
    }
  },

  methods: {
    ...mapMutations('modal', ['toggle']),
    ...mapActions('user', ['fetchUser']),

    close () {
      this.$router.push(this.localeLocation('/'))
      this.toggle()
    },

    async onLinkClick () {
      this.$nuxt.$loading.start()

      try {
        const { data } = await UsersService.linkSocial(this.$axios, 'telegram')

        await this.fetchUser()

        this.tgData = data.data
      } catch (e) {
        this.$errors.handle(e)
      }

      this.$nuxt.$loading.finish()
    }
  }
}
</script>

<template>
  <div :class="$style.modal">
    <p :class="$style.title">
      {{ $t('socialNetworks') }}
    </p>

    <div :class="$style.tg">
      <div :class="$style.left">
        <div :class="$style.iconContainer">
          <img :src="require(`~/assets/img/icons/telegram.svg`)" :class="$style.icon" alt="tg" />
        </div>
        <div>
          <span>Telegram</span>
          <div v-if="tgData" :class="$style.main">
            <span v-html="$t('notificationsTelegram', { link: tgData.botUrl })" />
            <CopyInput :class="$style.copy" :value="tgData.code" :icon="null" />
          </div>
        </div>
      </div>

      <Button
        v-if="!isTelegramLinked && !tgData"
        is-primary
        @click="onLinkClick"
      >
        {{ $t('buttonLink') }}
      </Button>
    </div>

    <div :class="$style.footer">
      <Button v-if="tgData" is-primary @click="close">
        {{ $t("buttonOk") }}
      </Button>
      <Button v-else is-secondary :class="$style.skip" @click="close">
        {{ $t("skip") }}
      </Button>
    </div>
  </div>
</template>

<style>
.modal--linktelegram {
  width: 100%;
  max-width: 688px !important;
}
</style>

<style lang="scss" module>
.modal {
  width: 100%;
  padding-top: 10px;
}

.title {
  color: $white;
  font-size: 32px;
  line-height: 120%;
  font-weight: 700;
  margin-bottom: 16px;
  padding-bottom: 22px;
  border-bottom: 1px solid $blue-grey40;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 0 0;
  border-top: 1px solid $blue-grey40;
}

.skip {
  min-width: 100% !important;

  @media (min-width: $breakpointTablet) {
    min-width: 328px !important;
  }
}

.tg {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 12px;
  align-items: start;
  margin-bottom: 16px;
}

.main {
  margin-top: 24px;
  color: $white;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;

  a {
    color: $primary40;
  }
}

.copy {
  margin-top: 12px;
  max-width: 200px;
}

.left {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: max-content;
  color: $white;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.icon {
  &Container {
    padding-right: 24px;
    margin-right: 24px;
    border-right: 1px solid $blue-grey40;
  }

  path {
    fill: $primary60;
  }
}
</style>
