<template lang="pug">
  .mobile
    .mobile-menu
      template(
        v-for="item in items"
        v-if="!['profile', 'notifications'].includes(item.id)  || user"
      )
        template(v-if="item.id === 'chat' && !isAuthorized")
          button.mobile-menu__item.mobile-menu__item--support(key="v-support-chat-btn" v-support-button)
            img(v-if="item.icon" :src="require(`~/assets/img/icons/${item.icon}.svg`)" :width="item.width" :height="item.height" alt="icon")
            span(v-if="item.title") {{ item.title }}

            .mobile-menu__button(v-if="item.id === 'button'" @click="item.action($event)")
              span(v-if="item.text") {{ item.text }}

        .mobile-menu__item(v-else :key="item.id" :class="{'--active': item.isActive()}" @click="item.action($event)")
          img(v-if="item.icon" :src="require(`~/assets/img/icons/${item.icon}.svg`)" :width="item.width" :height="item.height" alt="icon")
          span(v-if="item.title") {{ item.title }}

          .mobile-menu__button(v-if="item.id === 'button'" @click="item.action($event)")
            span(v-if="item.text") {{ item.text }}

    transition(name="slide-fade")
      BottomModal(v-if="isInfoVisible" @close="closeInfo")
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import BottomModal from '~/components/BottomModal/BottomModal.vue'

export default {
  name: 'MobileMenu',

  components: { BottomModal },

  data () {
    return {
      isInfoVisible: false
    }
  },

  computed: {
    ...mapState('user', ['user']),

    isAuthorized () {
      return !!this.user
    },

    items () {
      return [
        {
          id: 'store',
          title: this.$t('marketplace'),
          icon: 'store',
          width: 20,
          height: 20,
          action: () => {
            this.$gtag.event('marketplace_click', { account_id: this.user?.id, place: 'mobile_menu' })
            this.$router.push(this.localeLocation('/'))
          },
          isActive: () => this.$route.path === '/' || this.$route.path === `/${this.$i18n.locale}` || this.$route.path.startsWith('/marketplace') || this.$route.path.startsWith('/?')
        },
        {
          id: 'earn',
          title: this.$t('earn'),
          icon: 'earn',
          width: 20,
          height: 20,
          action: () => {
            this.$router.push(this.localeLocation('/missions'))
          },
          isActive: () => this.$route.path?.startsWith('/missions')
        },
        {
          id: 'games',
          title: this.$t('play'),
          icon: 'gamepad2',
          width: 20,
          height: 20,
          action: () => {
            this.$router.push(this.localeLocation('/games'))
          },
          isActive: () => this.$route.path === '/games'
        },
        {
          id: 'chat',
          title: this.$t('chat'),
          icon: 'chatvvv',
          width: 20,
          height: 20,
          action: () => {
            this.openChat()
          },
          isActive: () => {}
        }
      ]
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapMutations('notifications', ['toggleNotifications']),
    ...mapMutations('chat', ['setChatId', 'setChatName']),

    openRegistrationModal () {
      this.showModal({
        component: 'Auth',
        data: {
          mode: 'register'
        }
      })
    },

    showInfo () {
      this.$gtag.event('click_open_my_vvv_modal', {})
      this.isInfoVisible = true
    },

    closeInfo () {
      this.$gtag.event('click_close_my_vvv_modal', {})
      this.isInfoVisible = false
    },

    openChat () {
      if (!this.isAuthorized) {
        // this.openRegistrationModal()
        return
      }

      this.setChatId('ALL')
      this.setChatName(this.$t('chat'))
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

.mobile {
  overflow: hidden;
}

.mobile-menu {
  height: 48px;
  position: fixed;
  width: 100%;
  display: flex;
  background: $blue-grey90;
  border-top: 1px solid $blue-grey50;
  left: 0;
  bottom: -2px;
  z-index: 9;

  &__item {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    background: $blue-grey90;
    color: $blue-grey10;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 6px;
    transition: color 0.2s $easing;
    cursor: pointer;
    position: relative;

    &-dot {
      position: absolute;
      top: 8px;
      right: 28px;
      height: 8px;
      width: 8px;
      background-color: $pink60;
      border-radius: 50%;
      display: inline-block;
    }

    &.--highlight {
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        border: 2px solid $amber60;
        border-radius: 8px;
      }
    }

    &.--active {
      color: $primary60;

      ::v-deep img {
        path {
          fill: $primary60;
        }
      }
    }

    img {
      margin-bottom: 2px;

      path {
        //fill: #fff;
        transition: fill 0.2s $easing;
      }
    }
  }

  &__button {
    position: absolute;
    top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: 5px;
    font-size: 10px;
    line-height: 14px;
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    color: $white;
    background: linear-gradient(90deg, #1565C0 0%, #1E88E5 100%);
    border: 6px solid $blue-grey90;
    border-radius: 100px;
  }
}
</style>
