<template lang="pug">
  .player-row
    .player-row__cells
      .player-row__cell(v-if="withPlace || withHalfStar" class="--place")
        .player-row__halfstar(v-if="withHalfStar")
          img(:src="require(`~/assets/img/icons/halfstar.svg`)" alt="half of star")
        .player-row__place(v-else)
          img(:src="require(`~/assets/img/icons/star.svg`)" alt="star")
          span {{ player.place }}

      .player-row__cell(v-if="withPoints && player.points" class="--place")
        .player-row__place
          img(:src="require(`~/assets/img/icons/kill.svg`)" alt="points")
          span {{ player.points }}

      .player-row__avatar-container
        //.player-row__status
        //  .player-row__status-stars
        //    .player-row__status-star(v-for="i in 5" :key="i")
        //      img(:src="require('~/assets/img/star.svg')" :class="getStarClass(i)")
        .player-row__avatar(v-if="player.avatar || withPlace" :class="{ '--dashed': !player.avatar }" @click="$router.push(`/user/${player.alias}`)")
          img(v-if="player.avatar" :src="player.avatar", :alt="player.full_name" height="56" width="56" v-lazy-load)
          img(v-else :src="require(`~/assets/img/icons/player-empty.svg`)" alt="empty")

      .player-row__cell(class="--wide")
        .player-row__name
          .player-row__player(v-if="withGameAccount && gameAccount")
            .player-row__player-game-name
              PlayerGameName(:player="gameAccount" with-highlighting :account="gameAccount")
              .player-row__player-capitan(v-if="player.is_captain") {{ $t('captain') }}
            .player-row__player-name(:class="{'--me': isMe}")
              span {{ $t('onPlatform') }}
              NuxtLink(v-if="player.full_name" :to="localeLocation(`/user/${player.alias}`)") {{ player.full_name }}

          NuxtLink(v-else-if="player.full_name" :to="localeLocation(`/user/${player.alias}`)") {{ player.full_name }}

          .player-row__player.player-row__mobile(v-else-if="withPlace || withReward")
            .player-row__player-game-name(v-if="!isSponsored") {{ $t('withSubscription') }}
            .player-row__player-name(v-if="withPlace || withReward")
              Currency(:type="currency" size="20") {{ playerCurrency === 'coins' ? (player.coins / 20).toFixed(2) : player.coins }}
            .player-row__player-game-name(v-if="!isSponsored") {{ $t('withoutSubscription') }}
            template(v-if="!isSponsored")
              .player-row__player-name(v-if="withPlace || withReward")
                Currency(v-if="currency === 'money'" type="coins" size="20") {{ playerCurrency === 'coins' ?  (player.coins * 100).toFixed(0) : (player.coins * 1200).toFixed(0) }}
              .player-row__player-name(v-else-if="withPoints")
                Currency(:type="currency" size="20") {{ player.point_coins }}

        .player-row__right(v-if="$slots.controls")
          slot(name="controls")

        .player-row__cell.player-row__controls.player-row__mobile(v-if="$slots.controls")
          slot(name="controls")

        .player-row__right(v-else-if="player.is_on_moderation || player.ban_reason || player.is_prize_removed")
          .player-row__right-value
            .player-row__status-message(v-if="player.is_on_moderation") {{ $t('stateOnModeration') }}
            .player-row__status-message(v-if="player.ban_reason") {{ $t('blockedFor', { reason:  player.ban_reason }) }}
            .player-row__status-message(v-if="player.is_prize_removed") {{ $t('appealedReward') }}

        .player-row__desktop(v-else-if="withPlace || withReward")
          .player-row__name
            .player-row__player-game-name
              template(v-if="!isSponsored")
                | {{ $t('withSubscription') }}
              Currency(:type="currency") {{ playerCurrency === 'coins' ? (player.coins / 20).toFixed(2) : player.coins }}
          .player-row__name(v-if="!isSponsored")
            .player-row__player-game-name(style="margin-left: 12px")
              | {{ $t('withoutSubscription') }}
              Currency(v-if="currency === 'money'" type="coins" size="20")  {{ playerCurrency === 'coins' ?  (player.coins * 100).toFixed(0) : (player.coins * 1200).toFixed(0) }}

        .player-row__right.player-row__desktop(v-else-if="withPoints")
          .player-row__right-value
            Currency(:type="currency")
              b
                | {{ player.point_coins }}

        .player-row__points(v-if="withWinnerPoints && player.winner_points") {{ player.winner_points }}

      .player-row__cell.player-row__controls(v-if="withMenu && tournament")
        .player-row__report-button-container
          Tooltip(v-if="isReportDisabled" popup-class-name="player-row__controls-popup")
            Button.player-row__report-button(is-secondary is-disabled icon="warning" @click="showReportModal")
            template(v-slot:tooltip) {{ $t('complainConditions') }}
          Button.player-row__report-button(v-else is-secondary icon="warning" @click="showReportModal") {{ $t('complain') }}

        //PlayerMenu(:player="player" location="tournament" :tournament="tournament")

    .player-row__bottom(v-if="withGameAccount || withPoints || withPlace || withReward || $slots.controlsBottom")
      template(v-if="$slots.controlsBottom")
        .player-row__bottom-item
          slot(name="controlsBottom")

      template(v-else)
        .player-row__bottom-item(v-if="player.is_on_moderation || player.ban_reason || player.is_prize_removed")
          .player-row__bottom-value.player-row__bottom-ban
            .player-row__status-message(v-if="player.is_on_moderation") {{ $t('stateOnModeration') }}
            .player-row__status-message(v-if="player.ban_reason") {{ $t('blockedFor', { reason:  player.ban_reason }) }}
            .player-row__status-message(v-if="player.is_prize_removed") {{ $t('appealedReward') }}

        .player-row__bottom-item(v-if="player.full_name && (withPlace || withReward)")
          .player-row__bottom-title {{ $t('reward') }}
          .player-row__bottom-value
            Currency(:type="playerCurrency") {{ playerCurrency === 'coins' ? player.coins.toString().replace('.', '') : player.coins }}

        .player-row__bottom-item(v-else-if="player.full_name && withPoints")
          .player-row__bottom-title {{ $t('reward') }}
          .player-row__bottom-value
            Currency(:type="currency") {{ player.point_coins }}

</template>

<script>
import { mapState } from 'vuex'
import PlayerGameName from '~/components/PlayerGameName/PlayerGameName'
import PlayerMenu from '~/components/PlayerMenu/PlayerMenu'
import TournamentMixin from '~/mixins/Tournament'
import Tooltip from '~/components/Tooltip/Tooltip'
import UsersService from '~/services/UsersService'

export default {
  name: 'PlayerRow',

  components: { Tooltip, PlayerMenu, PlayerGameName },

  mixins: [TournamentMixin],

  props: {
    player: {
      type: Object,
      required: true
    },

    withGameAccount: {
      type: Boolean
    },

    withMenu: {
      type: Boolean
    },

    withPlace: {
      type: Boolean
    },

    withHalfStar: {
      type: Boolean
    },

    withReward: {
      type: Boolean
    },

    withPoints: {
      type: Boolean
    },

    withWinnerPoints: {
      type: Boolean
    },

    currency: {
      type: String,
      default: 'coins'
    },

    playerCurrency: {
      type: String,
      default: 'coins'
    },

    location: {
      type: String,
      required: true
    },

    tournament: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      stars: []
    }
  },

  computed: {
    ...mapState('user', ['user']),

    starsCount () {
      return this.stars.length
    },

    isReportDisabled () {
      const MINUTES_AFTER_FINISH = 10

      const isTournamentInProgress =
        this.tournament.state !== 'open' &&
        this.$dateFns.differenceInMinutes(new Date(), this.endsAt) <
          MINUTES_AFTER_FINISH

      return !isTournamentInProgress
    },

    gameAccount () {
      return (
        this.player.game_account ||
        this.player.game_accounts?.find(acc => acc.game === this.game?.name)
      )
    },

    isMe () {
      return this.player?.id === this.user?.id
    },

    isSponsored () {
      return this.tournament?.is_sponsored
    }
  },

  mounted () {
    this.loadUserStatus()
  },

  methods: {
    loadUserStatus () {
      UsersService.getUserStatus(this.$axios, this.player?.id).then((response) => {
        this.stars = response.data?.data
      })
    },

    getStarClass (i) {
      if (i > this.starsCount) {
        return 'player-row__status-star-img--grey'
      }
      return 'player-row__status-star-img'
    },

    showReportModal () {
      if (!this.$store.getters['user/isAuthorized']) {
        this.showModal({
          component: 'Auth',
          data: {
            mode: 'login',
            afterLogin: () => {
              this.showReportModal()
            }
          }
        })

        return
      }

      this.showModal({
        component: 'ReportPlayer',
        data: {
          player: this.player,
          location: this.location,
          tournament: this.tournament
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.player-row {
  margin-top: 8px;
  border-radius: 8px;
  background: $blue-grey70;
  color: $white;

  &__mobile {
    @media (min-width: $breakpointTablet) {
      display: none;
    }
  }

  &__desktop {
    display: none;

    @media (min-width: $breakpointTablet) {
      display: block;
    }
  }

  &__status {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    padding: 0 32px;

    @media (min-width: $breakpointTablet) {
      justify-content: flex-start;
      padding: 0 16px;
    }

    &-label {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: $blue-grey10;

      @media (min-width: $breakpointTablet) {
        margin-right: 12px;
      }
    }

    &-stars {
      display: grid;
      grid-template-columns: repeat(5, 8px);
      grid-gap: 3px;
      align-items: center;
      cursor: pointer;
    }

    &-star {
      &-img {
        width: 8px;
        height: 8px;

        &--grey {
          path {
            fill: $blue-grey50;
          }
        }
      }

      &:nth-child(1) {
        margin-bottom: -20px;
      }

      &:nth-child(2) {
        margin-bottom: -10px;
      }

      &:nth-child(3) {
        margin-bottom: -6px;
      }

      &:nth-child(4) {
        margin-bottom: -10px;
      }

      &:nth-child(5) {
        margin-bottom: -20px;
      }
    }
  }

  &__report-button-container {
    width: 32px;
    height: 32px;
    position: relative;

    .tooltip {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__report-button.button--secondary {
    width: 32px;
    height: 32px;
    padding: 0;
    position: absolute;
    right: 0;
    bottom: 0;

    &:not([disabled]):hover {
      height: 48px;
      width: 175px;
      margin-bottom: -8px;

      .button__text {
        width: 105px;
        margin-left: 9px;
        opacity: 1;
      }
    }

    .button {
      &__text {
        width: 0;
        opacity: 0;
        overflow: hidden;
        transition: all 0.2s $easing, width 0.1s linear;
      }

      &__icon {
        margin-right: 0;
        display: flex;
        align-items: center;
      }
    }
  }

  &__cells {
    display: flex;
    width: 100%;
    min-height: 64px;
  }

  &__player {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: auto;

    &-capitan {
      @include text;

      margin-left: 8px;
      height: 20px;
      padding: 0 6px;
      border-radius: 8px;
      border: 1px solid $teal60;
      color: $teal60;
      display: flex;
      align-items: center;
    }

    &-game-name {
      line-height: 20px;
      display: flex;
      color: $blue-grey10;

      .player-game-name {
        flex-direction: row-reverse;

        &__name {
          @include hint;

          white-space: nowrap;
        }

        font-weight: bold;
        font-size: 20px;
        color: #fff;
      }
    }

    &-name {
      @include hint-small;

      margin-top: 4px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 14px;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        white-space: nowrap;
        margin-right: 4px;
        color: #9bb0cb;
      }

      a {
        color: #fff;
      }

      &.--me {
        a {
          color: $cyan50;
        }
      }
    }
  }

  &__cell {
    min-height: 64px;
    padding: 0 16px;
    border-right: 1px solid $blue-grey40;
    display: flex;
    align-items: center;

    &:last-child {
      border-right: none;
    }

    &.--wide {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: $breakpointMobile) {
        @include text-overflow;

        max-width: calc(100% - 50px);
      }

      & ~ .player-row__avatar {
        padding-left: 12px;
      }
    }

    &.--place {
      padding: 0;
      min-width: 67px;
      justify-content: center;
    }

    @media (max-width: $breakpointMobile) {
      border-right: none;

      &.--place {
        min-width: 0;
        width: auto;
        padding: 0 8px;
      }
    }
  }

  &__place,
  &__halfstar {
    display: flex;
    align-items: center;

    span {
      @include heading6;

      margin-left: 11px;
      color: $white;
    }
  }

  &:first-child &__place svg path {
    fill: $amber50;
  }

  &:nth-child(2) &__place svg path {
    fill: $grey05;
  }

  &:nth-child(3) &__place svg path {
    fill: $orange90;
  }

  &__avatar {
    cursor: pointer;
    border-radius: 12px;
    overflow: hidden;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: none;
    margin-left: 16px;
    min-width: 48px;

    @media (max-width: $breakpointMobile) {
      &:not(:first-child) {
        margin-left: 0;
      }
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &.--dashed {
      border: 1px dashed $grey20;
      min-height: auto;
      margin-top: 8px;

      img {
        height: 24px;
        width: 24px;
        object-fit: cover;
      }
    }

    img {
      height: 48px;
      width: 48px;
      min-width: 48px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__name {
    @include heading6;

    color: $white;
    margin-right: 8px;
    flex-grow: 2;
    width: 100%;
    font-weight: 500;
    letter-spacing: 0.2px;
    font-size: 17px;

    @media (max-width: $breakpointMobile) {
      margin-right: 0;
    }

    a {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
      letter-spacing: 0.2px;
      font-size: 17px;
    }

    .currency {
      @include hint;
    }
  }

  &__right {
    &-value {
      display: flex;
      align-items: center;

      b {
        font-family: Roboto, sans-serif;
        font-weight: 500;
      }
    }

    @media (max-width: $breakpointMobile) {
      display: none;
    }
  }

  &__bottom {
    margin-top: 8px;
    padding: 0 16px;
    display: none;

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 16px;
    }

    &-title {
      @include caption-small;

      color: $blue-grey10;
    }

    &-ban {
      width: 100%;
    }

    @media (max-width: $breakpointMobile) {
      display: block;
    }
  }

  &__status-message {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #95a9c4;
    margin-top: 4px;
    white-space: normal;
    text-align: right;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;

    @media (max-width: $breakpointMobile) {
      font-size: 12px;
    }
  }

  &__points {
    @include info;

    color: $white;
  }
}

.--player-row__controls-popup {
  @include heading7;

  color: $blue-grey05;
  max-width: 300px;
  padding: 8px;

  &.--top-left,
  &.--bottom-left {
    margin: 0 0 0 -4px;
  }

  @media (max-width: $breakpointMobile) {
    &.--top-left {
      margin-top: -4px !important;
    }

    &.--bottom-left {
      margin-top: 4px !important;
      margin-bottom: -4px !important;
    }
  }
}
</style>
