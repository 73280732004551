<template lang="pug">
.tournament-settings
  // TODO: вынести отдельно эту часть, чтоб использовать её и тут и в PhaseModal
  .tournament-settings__access(v-if="!isPhased" ref="lobby")
    template(v-if="hasAccess")
      template(v-if="isLichess && hasAnticheat")
        .tournament-settings__title {{ $t('accessToTheTournament') }}
        CopyInput(v-if="tournament.password" :value="tournament.password" icon="lock-open" :title="$t('tournamentPassword')")
        .tournament-settings__hint.tournament-settings__hint--password(v-else) {{ $t('lobbyPasswordWillBeSoon') }}
        .tournament-settings__buttons
          Button(is-primary @click="onEnterEventClick") {{ $t('goToGame') }}
          VideoChatButton(v-if="webcamLink" @click="onWebcamClick") {{ $t('videoLaunch') }}

      template(v-if="isPubg || isFreeFire || isPubgNewState || isDota || isFortnite || isCallOfDuty")
        .tournament-settings__title {{ $t('accessToTheTournament') }}
        .tournament-settings__slots(v-if="tournament.teams && team")
          FormInput(:value="(teamIndex + 1).toString()" is-disabled :title="$t('teamSlot')")
          Button(is-secondary @click="onSlotsClick") {{ $t('checkOutSlot') }}

        template(v-if="!pubgLobby")
          .tournament-settings__hint {{ $t('lobbyDataWillBeSoon') }}
          .tournament-settings__hint.tournament-settings__hint--password {{ $t('lobbyPasswordWillBeSoon') }}
        template(v-else)
          CopyInput(:value="pubgLobby" icon="lock-open" :title="$t('lobbyId')")
          CopyInput(v-if="pubgPassword" :value="pubgPassword" icon="lock-open" :title="$t('tournamentPassword')")

      template(v-if="isClashRoyale")
        .tournament-settings__title {{ $t('accessToTheTournament') }}
        template(v-if="!clashRoyaleTag")
          .tournament-settings__hint {{ $t('lobbyDataWillBeSoon') }}
          .tournament-settings__hint.tournament-settings__hint--password {{ $t('lobbyPasswordWillBeSoon') }}
        template(v-else)
          CopyInput.data__lobby-input(v-if="clashRoyaleTag" :value="clashRoyaleTag" icon="lock-open" :title="$t('tag')")
          CopyInput.data__lobby-input(v-if="clashRoyalePassword" :value="clashRoyalePassword" icon="lock-open" :title="$t('password')")
        Button(is-primary @click="onEnterEventClick") {{ $t('openGame') }}

      template(v-if="isBrawlStars")
        .tournament-settings__title {{ brawlStarsRoomCode ? $t('enterIsOpen') : $t('accessToTheTournament') }}
        .tournament-settings__hint(v-if="brawlStarsRoomCode") {{ $t('useCodeOrClickButton') }}
        .tournament-settings__hint(v-else) {{ $t('lobbyDataWillBeSoon') }}
        CopyInput(v-if="brawlStarsRoomCode" :value="brawlStarsRoomCode" icon="lock-open" :title="$t('lobbyId')")
        .tournament-settings__hint.tournament-settings__hint--password(v-else) {{ $t('lobbyPasswordWillBeSoon') }}
        Button(is-primary :is-disabled="!brawlStarsRoomCode" @click="onEnterEventClick") {{ $t('enterLobby') }}

      template(v-if="isPubgDesktop")
        .tournament-settings__title {{ $t('accessToTheTournament') }}
        .tournament-settings__slots(v-if="tournament.teams && team")
          FormInput(:value="(teamIndex + 1).toString()" is-disabled :title="$t('teamSlot')")
          Button(is-secondary @click="onSlotsClick") {{ $t('checkOutSlot') }}
        template(v-if="!pubgLobby")
          .tournament-settings__hint {{ $t('lobbyDataWillBeSoon') }}
          .tournament-settings__hint.tournament-settings__hint--password {{ $t('lobbyPasswordWillBeSoon') }}
        CopyInput(v-if="pubgLobby" :value="pubgLobby" icon="lock-open" :title="$t('lobbyId')")
        CopyInput(v-if="pubgPassword" :value="pubgPassword" icon="lock-open" :title="$t('tournamentPassword')")

      template(v-if="isStandoff || isMobileLegends && ['open', 'in_progress'].includes(tournament.state)")
        template(v-if="isStandoff")
          .tournament-settings__title {{ $t('howToStartToPlay') }}
          ol.tournament-settings__list(v-html="$t('standoffMatchHint')")
        template(v-if="isMobileLegends")
          .tournament-settings__title {{ $t('howToStartToPlay') }}
          ol.tournament-settings__list(v-html="$t('standoffMatchHint')")
        template(v-if="canSeeStandoffPlayers")
          .tournament-settings__title {{ $t('matchMembers') }}

          .tournament-settings__accounts
            .tournament-settings__accounts-item(v-for="player in tournament.players")
              img.tournament-settings__accounts-image(:src="player.avatar")

              .tournament-settings__accounts-text
                .tournament-settings__accounts-name {{ player.game_account.username }}
                .tournament-settings__accounts-id ID: {{ player.game_account.id }}

              Button.tournament-settings__accounts-copy(
                is-square
                icon="copy"
                :title="$t('copyInput')"
                @click="onCopyGameIdClick(player)"
              )

        InfoMessage.tournament-settings__warning(v-else type="warning") {{ $t('idsWillArriveAtStartOfTournament') }}

      //Button.tournament-settings__overwolf-button(v-if="game.isOverwolf" is-primary @click="onOverwolfClick('open')") {{ $t('openOverwolf') }}

      //template(v-if="game.isOverwolf")
      //  .tournament-settings__title(class="--overwolf" v-html="$t('accessToTheTournamentOverwolf')")
      //
      //  Button.tournament-settings__overwolf-button(is-primary @click="onOverwolfClick('open')") {{ $t('buttonLaunch') }}
      //  Button(is-secondary @click="onOverwolfClick('download')") {{ $t('overwolfDownload') }}

    template(v-else-if="isStandoff || isMobileLegends && ['open', 'in_progress'].includes(tournament.state) && isAuthorized && canSeeStandoffPlayers")
      .tournament-settings__title {{ $t('matchMembers') }}

      .tournament-settings__accounts
        .tournament-settings__accounts-item(v-for="player in tournament.players")
          img.tournament-settings__accounts-image(:src="player.avatar")

          .tournament-settings__accounts-text
            .tournament-settings__accounts-name {{ player.game_account.username }}
            .tournament-settings__accounts-id ID: {{ player.game_account.id }}

          Button.tournament-settings__accounts-copy(
            is-square
            icon="copy"
            :title="$t('copyInput')"
            @click="onCopyGameIdClick(player)"
          )

  .tournament-settings__body
    //.tournament-settings__banner
    //  img.tournament-settings__banner-logo--top(
    //    :src='require("~/assets/img/betfury-logo.svg")',
    //    alt='BetFury logo'
    //  )
    //  .tournament-settings__banner-title(v-t="'betFuryPagesBannerText'")
    //  Button.tournament-settings__banner-button(v-t="'participate'" is-primary @click="goToBetFury")
    //  img.tournament-settings__banner-background(:src='require("~/assets/img/betfury-banner-background.png")')
    template(v-if="(isPhased && tournament.players_count) || players && players.length")
      .tournament-settings__title {{ tournament.teams ? $t('teams') : $t('members') }}
      .tournament-settings__players
        Players(:players="players" :count="playersCount")
        a(href="#" @click.prevent="showPlayersModal") {{ $t('showAllPlayers') }}

    .tournament-settings__players_meta
      p(v-if="tournament.tournament_player_settings" v-tippy :content="$t('min_players_count')") Min
        b {{ tournament.tournament_player_settings.min_players_count || '-' }}
      p(v-if="tournament.tournament_player_settings" v-tippy :content="$t('max_players_count')") Max
        b {{ tournament.tournament_player_settings.max_players_count || '-' }}

    .tournament-settings__numbers
      .tournament-settings__number
        strong {{ (!isPhased && tournament.teams && tournament.teams.length) || tournament.players_count }}
          i(v-if="tournament.tournament_player_settings && tournament.tournament_player_settings.min_players_count")
        span {{ tournament.teams ? $t('teamsAvailable') : $t('statsAccounts') }}
      .tournament-settings__number(v-if="lobbyPlayersCount !== null")
        strong {{ lobbyPlayersCount }}
        span {{ $t('inGame') }}
      .tournament-settings__number
        strong {{ maxPlayersCount }}
        span {{ $t('freePlaces') }}

    .tournament-settings__bottom

      client-only
        .tournament-settings__time(v-if="!isJoinButtonVisible")
          template(v-if="tournament.state === 'open' && isCounterVisible")
            .tournament-settings__time-title {{ $t('timerStartsIn') }}
            .tournament-settings__time-value
              Countdown(:end-time="startsAt")
                span(slot="process" slot-scope="{ timeObj }")
                  | {{ timeObj.h }}:{{ timeObj.m }}:{{ timeObj.s }}
              .tournament-settings__time-value-small {{ $t('timePreposition') }} {{ timeText }}

          template(v-else)
            .tournament-settings__time-title {{ $t('start') }}:
            .tournament-settings__time-value {{ dateText }}

        .tournament-settings__time(v-if="isPhased")
          .tournament-settings__time-title {{ $t('end') }}:
          .tournament-settings__time-value {{ endDateText }}

      .tournament-settings__join(v-if="tournament.teams && canTeamJoinTournament && isTeamCaptain && regStarted",)
        Button(
          is-primary,
          @click='joinTournamentAsTeam'
        ) {{ $t('joinAsTeam', { teamName: team && team.name }) }}

      .tournament-settings__join(v-else-if="isJoinButtonVisible && regStarted && !needToWaitTeamToJoin")
        .tournament-settings__join-price(v-if="isPaid")
          img(:src="require(`~/assets/img/icons/cost.svg`)" alt="cost")
          .tournament-settings__join-price-text
            span {{ $t('entryFee').toLowerCase() }}
            strong {{ $t('entryContribution', { prizeFee: formatNumber(entryFeeMinusCommission), entryFee: formatNumber(commission) }) }}

        Button(is-primary id="tournament-settings-register-button" @click="onJoinTournamentClick" :is-disabled="!is24Finished")
          | {{ $t('join') }}

      .tournament-settings__time(v-if="!regStarted && regAt")
        .tournament-settings__time-title {{ $t('registrationStartsIn') }}
        .tournament-settings__time-value
          Countdown(:end-time="regAt")
            span(slot="process" slot-scope="{ timeObj }")
              template(v-if="timeObj.d") {{ timeObj.d }}:
              | {{ timeObj.h }}:{{ timeObj.m }}:{{ timeObj.s }}
          .tournament-settings__time-value-small {{ $t('timePreposition') }} {{ regTimeText }}

      Button.tournament-settings__team-button(v-if="isTeamNeeded" is-primary @click="openCreateTeamModal") {{ $t('assembleTeam') }}

      Button(
        v-if="!isPhased && isLichess && (tournament.state !== 'cancelled' && !canJoinTournament) && !hasAccess"
        is-secondary
        @click="onEnterEventClick"
      ) {{ $t('watchTournament') }}

      Button(
        v-else-if="areTournamentResultsNeeded",
        is-primary
        @click="onSendTournamentResultsClick"
      ) {{ $t('sendTournamentResults') }}

      SocialButtons(:tournament="tournament" :game-name="game.name")

      Button(
        v-if="canLeave && hasAccess && ['open', 'in_progress'].includes(tournament.state)"
        is-secondary
        @click="showLeaveTournamentPrompt(isPhased ? 'mega-tournaments' : 'tournaments')"
      ).tournament-settings__leave {{ $t('leaveTournament') }}

      Button.tournament-settings__help(is-secondary v-support-button icon="info") {{ $t('help') }}

  //.tournament-settings__blured(v-if="blurBody")
  //  .tournament-settings__blured-text {{ $t('getFreerollSubscription') }}
  //  Button(is-primary id="tournament-settings-subscribe-button" @click="onSubscribeClick") {{ $t('subscribeTo') }}

  // плавающая кнопка
  .tournament-settings__block-mobile
    .tournament-settings__mobile(v-if="areTournamentResultsNeeded")
      Button(is-primary @click="onSendTournamentResultsClick") {{ $t('sendTournamentResults') }}

    .tournament-settings__mobile(v-if="isTeamNeeded")
      Button(is-primary @click="openCreateTeamModal") {{ $t('assembleTeam') }}

    .tournament-settings__mobile(v-else-if="isJoinButtonVisible")
      .tournament-settings__mobile-row
        template(v-if="!regStarted")
          .tournament-settings__mobile-row-title {{ $t('registrationStartsIn') }}
          .tournament-settings__mobile-row-value
            Countdown(:end-time="regAt")
              span(slot="process" slot-scope="{ timeObj }")
                template(v-if="timeObj.d") {{ timeObj.d }}:
                | {{ timeObj.h }}:{{ timeObj.m }}:{{ timeObj.s }}
            .tournament-settings__mobile-row-value-small &nbsp;{{ $t('timePreposition') }} {{ regTimeText }}
      .tournament-settings__mobile-row
        template(v-if="tournament.state === 'open' && isCounterVisible")
          .tournament-settings__mobile-row-title {{ $t('timerStartsIn') }}
          .tournament-settings__mobile-row-value
            Countdown(:end-time="startsAt")
              span(slot="process" slot-scope="{ timeObj }")
                | {{ timeObj.h }}:{{ timeObj.m }}:{{ timeObj.s }}
            .tournament-settings__mobile-row-value-small &nbsp;{{ $t('timePreposition') }} {{ timeText }}
        template(v-else)
          .tournament-settings__mobile-row-title {{ $t('start') }}:
          .tournament-settings__mobile-row-value {{ dateText }}

      .tournament-settings__mobile-row(v-if="isPaid")
        .tournament-settings__mobile-row-title {{ $t('fee').toLowerCase() }}:
        .tournament-settings__mobile-row-value {{ $t('entryContribution', { prizeFee: entryFeeMinusCommission, entryFee: commission }) }}

      template(v-if="!tournament.isTournamentSponsored && !needToWaitTeamToJoin")
        Button(is-primary id="tournament-settings-register-button" @click="onJoinTournamentClick" :is-disabled="!regStarted || !is24Finished")
          | {{ $t('join') }}

</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TournamentMixin from '~/mixins/Tournament'
import Players from '~/components/Players/Players'
import VideoChatButton from '~/components/VideoChatButton/VideoChatButton'
import CopyInput from '~/components/CopyInput/CopyInput'
import FormInput from '~/components/FormInput/FormInput'
import SocialButtons from '~/components/SocialButtons/SocialButtons'
import InfoMessage from '~/components/InfoMessage/InfoMessage'

import { copyText } from '~/utils/utils'

export default {
  name: 'TournamentSettings',

  components: {
    SocialButtons,
    FormInput,
    VideoChatButton,
    Players,
    CopyInput,
    InfoMessage
  },

  mixins: [TournamentMixin],

  props: {
    tournament: {
      type: Object,
      default: null
    }
  },

  computed: {
    ...mapGetters('user', ['isAuthorized', 'isAdmin']),
    ...mapState('user', ['user', 'token']),

    playersCount () {
      function uniqueBy (a, cond) {
        return a.filter((e, i) => a.findIndex(e2 => cond(e, e2)) === i)
      }

      if (this.tournament.teams) {
        const players = []

        this.tournament.teams.forEach(({ members }) =>
          members.forEach((player) => {
            players.push(player)
          })
        )

        return uniqueBy(players, (a, b) => a.id === b.id).length
      }

      if (this.tournament.players_count) {
        return this.tournament.players_count
      }

      return 0
    },

    players () {
      function uniqueBy (a, cond) {
        return a.filter((e, i) => a.findIndex(e2 => cond(e, e2)) === i)
      }

      if (this.tournament.teams) {
        const players = []

        this.tournament.teams.forEach(({ members }) =>
          members.forEach((player) => {
            players.push(player)
          })
        )

        return uniqueBy(players, (a, b) => a.id === b.id)
      }

      return this.tournament.players
    },

    teamIndex () {
      return this.tournament.teams?.findIndex(({ id }) => this.team?.id === id)
    },

    isJoinButtonVisible () {
      return this.canJoinTournament && !this.hasAccess
    },

    canLeave () {
      if (this.tournament.teams) {
        return false
      }

      if (this.game.name === 'standoff' && this.tournament.state !== 'open') {
        return false
      }

      const MINUTES_BEFORE_START = 15

      return (
        this.$dateFns.differenceInMinutes(this.startsAt, new Date()) >
        MINUTES_BEFORE_START
      )
    },

    lobbyPlayersCount () {
      let count = null
      const state = this.tournament.extra_data?.state

      if (this.isLichess) {
        count = state?.top?.length
      } else if (this.isPubg || this.isFreeFire || this.isPubgNewState) {
        count = state?.game?.extra_data?.players_count
      } else if (this.isClashRoyale) {
        count = state?.membersList?.length
      }

      if (isNaN(parseInt(count))) {
        return null
      }

      return count
    },

    canSeeStandoffPlayers () {
      if (!this.tournament?.players?.length) {
        return false
      }

      const diff = this.$dateFns.differenceInMinutes(new Date(), this.startsAt)

      return diff >= -14
    }
  },

  mounted () {
    setTimeout(this.showTabContentByDirectUrl, 500)
  },

  methods: {
    plural: require('plural-ru'),

    onSubscriptionClick () {
      if (!this.isAuthorized) {
        this.showModal({
          component: 'Auth',
          data: { mode: 'login' }
        })

        return
      }

      this.showModal({
        component: 'ModalAfterFreeroll',
        data: { isSignTry: true }
      })

      // this.showModal({
      //   component: 'ModalMessage',
      //   data: {
      //     type: 'warning',
      //     title: this.$t('pleaseBuySubscription'),
      //     text: this.$t('youNeedSubscriptionForParticipate'),
      //     buttons: [
      //       {
      //         props: { isPrimary: true },
      //         title: this.$t('getSubscription'),
      //         shouldClose: true,
      //         action: () =>
      //           this.$router.push(this.localeLocation('/store/subscriptions'))
      //       }
      //     ]
      //   }
      // })
    },

    showTabContentByDirectUrl () {
      const { hash } = window.location

      if (hash) {
        const block = hash.replace('#', '')
        const el = this.$refs[block]?.$el || this.$refs[block]

        el?.scrollIntoView?.({
          block: 'center',
          behavior: 'smooth'
        })
      }
    },

    onOverwolfClick (action) {
      if (action === 'download') {
        window.open(this.$config.OVERWOLF_DOWNLOAD_LINK, '_blank')
      } else if (action === 'open') {
        // window.open('gaming-goods.com://open', '_blank')
      }
    },

    onSlotsClick () {
      this.$store.dispatch('modal/showModal', {
        component: 'ModalSlots',
        data: { tournament: this.tournament }
      })
    },

    async showPlayersModal () {
      let players = this.tournament.players
      let bannedPlayers = this.bannedPlayersList

      if (this.isPhased) {
        this.$nuxt.$loading.start()

        try {
          const { data } = await this.$axios.get(
            `/games/${this.game.name}/mega-tournaments/${this.tournament.id}/${
              this.tournament.teams ? 'teams' : 'players'
            }`
          )

          players = data.data
          bannedPlayers = TournamentMixin.computed.bannedPlayersList.call({
            tournament: {
              ...this.tournament,
              players
            }
          })
        } catch (e) {
          console.error(
            `${new Date().toUTCString()} :: mega tournament players ::`,
            e
          )

          this.$errors.handle(e)
          return
        }

        this.$nuxt.$loading.finish()
      }

      if (this.tournament.teams) {
        this.$store.dispatch('modal/showModal', {
          component: 'PlayersListModal',
          data: {
            teams: players || this.tournament.teams,
            bannedPlayers,
            tournament: this.tournament,
            location: 'tournament',
            isPhased: this.isPhased
          }
        })

        return
      }

      this.$store.dispatch('modal/showModal', {
        component: 'PlayersListModal',
        data: {
          players,
          bannedPlayers,
          tournament: this.tournament,
          location: 'tournament',
          isPhased: this.isPhased
        }
      })
    },

    showLeaveTournamentPrompt (tournamentType = 'tournaments') {
      this.showModal({
        component: 'ModalMessage',
        data: {
          title: this.$t('stepConfirm'),
          text: this.$t('areYouSureYouWannaLeaveTournament'),
          type: 'warning',
          buttons: [
            {
              props: { isSecondary: true },
              title: this.$t('leaveTournament'),
              action: () => this.leaveTournament(tournamentType)
            },
            {
              props: { isPrimary: true },
              title: this.$t('stay')
            }
          ]
        }
      })
    },

    onCopyGameIdClick (player) {
      copyText(player.game_account.id)
    },

    onSubscribeClick () {
      this.$gtag.event('click_tournament_page_settings_subscribe_button', {})

      this.$router.push(this.localeLocation('/store/subscriptions'))
    },

    goToBetFury () {
      this.$router.push(this.localeLocation('/betfury'))
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/styles/tournament-settings";

.tournament-settings__bottom {
  position: relative;

  .button {
    width: 100%;
  }
}

.tournament-settings__block-mobile {
  @media (min-width: $breakpointTablet) {
    display: none;
  }
}

.tournament-settings__join-price-text {
  color: white;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-top: 9px;
  display: block;
  padding-bottom: 4px;
  line-height: 20px;
}

.tournament-settings__players_meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 18px;
  color: #9bb0cb;
  font-weight: 500;
  margin-top: 10px;

  p {
    cursor: pointer;

    b {
      color: white;
      padding-left: 4px;
    }
  }
}

.tournament-settings__hint--password {
  padding: 8px 24px;
  background-color: $blue-grey60;
  line-height: 20px;
  border-radius: 8px;
}

.tournament-settings__banner {
  padding: 20px 16px;
  position: relative;
  border-radius: 16px;
  background: linear-gradient(269.49deg, rgba(36, 20, 33, 0.1) 0%, #2A1422 47.73%, #331524 100%);
  width: 367px;

  &-background {
    position: absolute;
    top: 0;
    right: 0;
    object-fit: cover;
    height: 100%;
  }

  &-logo {
    &--top {
      height: 34px;
      margin-bottom: 16px;
    }
  }

  &-title {
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $white;
    max-width: 617px;
  }

  &-button {
    color: $white;
    padding: 24px;
    width: 328px;
    z-index: 2;
  }
}
</style>
