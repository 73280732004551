<template lang="pug">
  .clash-royale-account.brawl-stars-account
    .modal__header {{ $t('account') }} Brawl Stars

    .clash-royale-account__body
      Steps(:steps="stepsTitles" :current-step-index="stepIndex")

      .clash-royale-account__step(v-if="stepIndex === 0")
        .clash-royale-account__item
          FormInput(:title="$t('gameAccountTagTitle')" placeholder="#XXXXXX" v-model="playerTag" @input="onTagInput")
        .clash-royale-account__item
          ImageSlider(:slides="tagSlides" :slide-duration="2800")

      .clash-royale-account__step(v-if="stepIndex === 1")
        .clash-royale-account__item
          p.brawl-stars-account__caption {{ $t('brawlLinkNicknameColor') }}
          img.brawl-stars-account__color(:src="colorImageUrl" :alt="color")

      .clash-royale-account__step(v-if="stepIndex === 2")
        .fourth-step__first
          InfoMessage(v-if="!isSuccess" type="info" :title="isFirstTry ? $t('brawlLinkWait') : $t('brawlLinkWait2')")
            | {{ $t('brawlLinkWaitText') }}
          InfoMessage(v-else type="allow" :title="$t('brawlLinkSuccess')")

      InfoMessage(v-if="errorMessage" type="error") {{ errorMessage }}

    .modal__footer
      Button(v-if="!isSuccess && [1, 2].includes(stepIndex)" is-secondary @click="onPreviousStepClick") {{ $t('buttonBack') }}
      Button(is-primary :is-disabled="isButtonDisabled" @click="onNextClick")
        template
          | {{ buttonText }}
          .brawl-stars-account__loader(v-if="stepIndex === 2 && !isSuccess && secondsLeft > 0")
            img(:src="require('~/assets/img/VVV-logo.svg')" height="20" width="22" alt="logo")

</template>

<script>
import { mapActions } from 'vuex'
import ClashRoyaleAccount from '~/components/ClashRoyaleAccount/ClashRoyaleAccount'

const CDN_URL = 'https://cdn.gaming-goods.com/games/brawl-stars/verification/'
const FIRST_TRY_SECONDS = 30
const TRY_SECONDS = 15

let timerId = null

export default {
  name: 'BrawlStarsAccount',

  extends: ClashRoyaleAccount,

  data () {
    return {
      isSuccess: false,
      secondsLeft: FIRST_TRY_SECONDS,
      isFirstTry: true,

      /**
       *  Слева направо в игре, по каждой строчке
       *
       *  1. 0xffffffff
       *  2. 0xffa2e3fe
       *  3. 0xff4ddba2
       *  4. 0xffff9727
       *  5. 0xfff9775d
       *  6. 0xfff05637
       *  7. 0xfff9c908
       *  8. 0xffffce89
       *  9. 0xffa8e132
       *  10. 0xff1ba5f5
       *  11. 0xffff8afb
       *  12. 0xffcb5aff
       */
      color: ''
    }
  },

  computed: {
    stepList () {
      return [
        {
          title: this.$t('brawlLinkTag'),
          action: () => this.linkGameAccount()
        },
        {
          title: this.$t('brawlLinkColor'),
          action: () => {
            this.stepIndex++
          }
        },
        {
          title: this.$t('stepConfirm'),
          action: () => this.verifyGameAccount()
        }
      ]
    },

    stepsTitles () {
      return this.stepList.map(step => step.title)
    },

    tagSlides () {
      return [
        {
          text: this.$t('brawlLinkSliderText'),
          image: CDN_URL + 'slider_tag/1.gif'
          // image: require('./gif.gif'),
        },
        {
          text: this.$t('brawlLinkSliderText2'),
          image: CDN_URL + 'slider_tag/2.gif'
          // image: require('./gif.gif'),
        }
      ]
    },

    isButtonDisabled () {
      if (this.errorMessage) {
        return true
      }

      if (this.stepIndex === 0 && !this.playerTag) {
        return true
      }

      if (this.stepIndex === 2 && this.secondsLeft > 0) {
        return true
      }

      return false
    },

    colorImageUrl () {
      return `${CDN_URL}/colors/${this.color}.png`
    },

    buttonText () {
      if (this.stepIndex === 2 && !this.isSuccess) {
        if (this.secondsLeft > 0) {
          return `${this.$t('buttonCheck')} ${this.secondsLeft}`
        }

        return this.$t('buttonCheck')
      }

      return this.$t('buttonContinue')
    }
  },

  watch: {
    stepIndex (index) {
      if (index === 2) {
        this.isFirstTry = true
        this.secondsLeft = FIRST_TRY_SECONDS
        this.startTimer()
      } else {
        clearInterval(timerId)
      }
    }
  },

  beforeDestroy () {
    clearInterval(timerId)
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    onNextClick () {
      this.errorMessage = ''

      this.stepList[this.stepIndex].action()
    },

    startTimer () {
      clearInterval(timerId)

      timerId = setInterval(() => {
        this.secondsLeft -= 1

        if (this.secondsLeft <= 0) {
          clearInterval(timerId)
        }
      }, 1000)
    },

    async verifyGameAccount () {
      if (this.isSuccess) {
        // this.toggle()
        this.showModal({
          component: 'ModalTournamentsAfterAccountLink',
          canClose: false,
          data: {
            gameName: 'Brawl Stars',
            gameId: 4
          }
        })
        return
      }

      this.$nuxt.$loading.start()

      try {
        const { data } = await this.$axios.post(
          '/games/brawl-stars/account/verify'
        )

        if (data.data) {
          this.isSuccess = true
        } else {
          this.isFirstTry = false
          this.secondsLeft = TRY_SECONDS
          this.startTimer()
        }
      } catch (e) {
        this.errorMessage = this.$errors.getText(e)
      }

      this.$nuxt.$loading.finish()
    },

    async linkGameAccount () {
      // #G2QYYPU9

      let tag = this.playerTag

      if (!tag?.startsWith('#')) {
        tag = '#' + this.playerTag
      }

      tag = tag?.toUpperCase()

      this.$nuxt.$loading.start()

      try {
        const { data } = await this.$axios.post('/games/brawl-stars/account', {
          tag
        })

        this.$gtag.event('game_account_link', { step: 3, game: 'brawl-stars' })

        if (!data.error && data.data?.color) {
          this.color = data.data.color
          this.stepIndex += 1
        }
      } catch (e) {
        console.error(`${new Date().toUTCString()} :: BS account error ::`, e)

        let err = this.$errors.getText(e)

        if (e.response?.data?.code === 100) {
          err = this.$t('brawlLinkTagError')
        }

        this.errorMessage = err
      }

      this.$nuxt.$loading.finish()
    },

    onTagInput () {
      this.errorMessage = ''
    }
  }
}
</script>

<style lang="scss">
.modal.modal--brawlstarsaccount {
  padding-bottom: 0;
  max-width: 736px;
}

.brawl-stars-account {
  .button__text {
    display: flex;
    align-items: center;
  }

  &__loader {
    animation: rotate 1s infinite $easing;
    transform-origin: 52% 38%;
    height: 20px;
    width: 22px;
    position: relative;
    margin-left: 10px;

    svg path {
      fill: $white;
    }

    //&::after {
    //  content: '';
    //  width: 1px;
    //  height: 1px;
    //  left: 52%;
    //  top: 38%;
    //  background: red;
    //  position: absolute;
    //}
  }

  &__color {
    margin: 0 auto;
    display: block;
  }

  &__caption {
    @include hint;

    color: $white;
    text-align: center;
    margin-bottom: 31px;
  }

  .info-message.--error {
    margin-top: 16px;
  }
}
</style>
